<!--
 * @Descripttion: 滚动布局
 * @Author: 汪佳彪
 * @date: 2021-11-06 13:57
-->
<template>
  <div class="overview-layout">
    <!-- <div class="overview-layout-header" ref="Header">
      <slot name="header"></slot>
    </div> -->
    <el-scrollbar
      ref="scrollbar"
      :class="{
        'overview-layout-content': true,
        'footer-absolute': footerType === 'fixed',
      }">
      <div class="wrapper"><slot></slot></div>
      <!-- <RecordNo v-if="footerType === 'scroll'" /> -->
    </el-scrollbar>
    <!-- <RecordNo v-if="footerType === 'fixed'" style="position: absolute; bottom: 0; width: 100%" /> -->
  </div>
</template>

<script>
  import { addResizeListener, removeResizeListener } from 'element-ui/src/utils/resize-event';

  export default {
    name: 'OverviewLayout',
    components: {
      // 备案号
      // eslint-disable-next-line
      RecordNo: {
        // eslint-disable-next-line no-unused-vars
        render(h) {
          return (
            <div class="footer">
              <div style="white-space: nowrap; overflow:hidden;">
                备案号：
                <a target="_blank" href="http://beian.miit.gov.cn/">
                  沪ICP备15000733号-1
                </a>
                © www.evchong.com All rights reserved.
                <span class="test">版权所有：上海森通智达新能源技术服务有限公司</span>
              </div>
            </div>
          );
        },
      },
    },
    data() {
      return {
        footerType: 'scroll',
      };
    },
    activated() {
      this.$nextTick(this.update);
    },
    mounted() {
      const vm = this.$refs.scrollbar;
      if (vm && vm.$refs.resize) {
        // 滚动区域resize
        addResizeListener(vm.$refs.resize, this.update);
        // window resize
        window.addEventListener('resize', this.update);
        this.$once('hook:beforeDestroy', () => {
          removeResizeListener(vm.$refs.resize, this.update);
          removeResizeListener(this.$refs.Header, this.update);
          window.removeEventListener('resize', this.update);
        });
      }
    },
    methods: {
      update() {
        const vm = this.$refs.scrollbar;
        if (vm && typeof vm.update === 'function') {
          // 滚动条更新
          vm.update();
        }
        // 更新底部备案号
        if (vm && vm.wrap) {
          const { scrollHeight, clientHeight } = vm.wrap;
          // console.log(clientHeight, scrollHeight);
          if (scrollHeight === clientHeight) {
            this.footerType = 'fixed';
          } else {
            this.footerType = 'scroll';
          }
        }
      },
    },
  };
</script>

<style lang="scss">
  @import '~@/components/ykc-ui/styles/var.scss';
  .overview-layout {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &-header {
      margin: 10px 20px 10px 20px;
      > *:not(:first-child) {
        margin-top: 12px;
      }
      .ykc-button + .ykc-button {
        margin-left: 8px;
      }
    }
    &-content {
      // &.footer-absolute {
      //   .el-scrollbar__bar.is-horizontal {
      //     bottom: 22px;
      //   }
      // }
      flex: 1;
      .el-scrollbar__wrap {
        background-color: rgba(1, 25, 64, 1);
        width: 100%;
        &::-webkit-scrollbar {
          display: none;
        }
        scrollbar-width: none;
        scrollbar-color: transparent transparent;
        .el-scrollbar__view {
          > div {
            // padding: 0 28px 0 23px;
            // min-width: 1246px;
          }
        }
      }
      .el-scrollbar__bar {
        &.is-vertical {
          opacity: 1;
        }
        &.is-horizontal {
          z-index: 20;
        }
      }
    }
    .footer {
      color: #2879a0;
      font-size: 12px;
      text-align: center;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.7;
      font-weight: 'Regular';
      transform: scale(0.84);
      > div {
        font-size: 16px;
        transform: scale(0.625);
        a {
          color: #2879a0;
          margin-right: 4px;
          text-decoration: none;
        }
      }
    }
  }
</style>
